import { defineStore } from 'pinia';

export const useReportStore = defineStore(
  'report-store',
  () => {

    const saveReport = ref([])
    const reportId = ref(null)
    const reportName = ref('')
    const selectedOptions = ref([])
    const isCustomReport = ref(false)


    return {
      saveReport,
      reportId,
      reportName,
      selectedOptions,
      isCustomReport,
    };
  }
);
